//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    import { iScroll } from '@/icomponents';
    import {miceService} from "@/service/miceService";
    export default {
        components: {
            iScroll
        },
        data () {
            return {
                personList: [],
                pageIndex: 1,
                pageSize: 100,
                allLoaded: false,
                topHeight: 0,
                bottomHeight: 0,
                scrollTop: 0,
            }
        },
        mounted() {
            this.getScrollBoxHeight();
        },
        created() {
            this.init()
        },
        methods: {
            init () {
                this.getinvitationPerson()
            },
            goBack () {
                this.$router.push('/addPerson')
            },
            getinvitationPerson () {
                console.log(this.pageSize)
                let params = {
                    MettingId: this.iStorage.get('selectItem').mettingId,
                    pageNo: this.pageIndex,
                    pageSize: this.pageSize,
                    status: 1
                }
                this.allLoaded = false
                miceService.invitationQuery(params).then(res => {
                    if (res.success) {
                        this.allLoaded = true
                        this.$refs.scrollWrapper.scrollListener();
                        this.personList = res.content
                    }
                })
            },
            //上拉下拉
            scrollLoadData() {
                this.getinvitationPerson();
            },
            //监听滚动
            loadmoreScroll(event) {
                this.scrollTop = event.target.scrollTop;
            },
            getScrollBoxHeight() {
                this.$nextTick(() => {
                    const event_header_container = document.getElementsByClassName("event_header_container");
                    this.topHeight = event_header_container ? event_header_container[0].clientHeight : 0;
                })
            }
        }
    }
